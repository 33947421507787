<template>
	<v-card>
		<s-toolbar
			dark
			color="#BDBDBD"
			:label="dataCalifornian.ClfName"
			close
			@close="closeDetail"> 
		</s-toolbar>
		<v-row>
			<div class="col-md-12 col-sm-12">
				<v-card outlined>
					<v-row style="margin: auto;" justify="center">
						<v-col
							align="center"
							cols="12"
						>
							<h2><b>{{dataCalifornian.ClfName}} en proceso de: <v-chip :color="dataCalifornian.inCalifornianStateColor">{{dataCalifornian.inCalifornianStateName}}</v-chip></b></h2>
						</v-col>
					</v-row>
				</v-card>

				<v-card dense>
					<v-row style="margin: auto;">

						<v-alert
							v-model="alert"
							border="right"
							:color="dataCalifornian.inCalifornianStateColor"
							dark
							dense
							shaped
							style="color: black; border-radius: 10px 10px 10px 10px; z-index: 5; width: 100%; right: 0px; top: 5%; margin-top: ;}"
						>
						<!-- #9EB8F6 -->
							<div>
								<v-row justify="center">
									<v-col cols="6" lg="4" md="4" >
										<b>Capacidad Total: {{dataCalifornian.ClfCapacity}}</b>
										
									</v-col>
									<v-col cols="6" lg="4" md="4">
										<b>Capacidad Usado: {{dataCalifornian.inParihuelaUsage}}</b>
										
									</v-col>
									<v-col cols="12" lg="4" md="4" align="center">
										<b>Capacidad Disponible: {{dataCalifornian.ParihuelaAvailableUnit}}</b>
										
									</v-col>
									<v-col cols="6" hidden>
										<s-select-definition
											label="Tipo parihuela"											 
											:def="1363"
											return-object											 
											@change="inputWeightGross($event)"
											>
										</s-select-definition>
									</v-col>
											
									<v-col hidden>
										<s-select-definition
											@getDataInitial="getDataInitial($event)"
											label="Proceso"
											:def="1231"
										>
										</s-select-definition>
									</v-col>
								</v-row>
							</div>
						</v-alert>

					</v-row>
				</v-card>

				<v-card dense>
					<v-container>
						<v-row style="margin-left: 5px" >
							<v-col cols="12" md="4" lg="3" class="pb-0 pt-1">
								<s-text                          
								v-model="filter.LlpCorrelative"
								label="Pallet"
								@input="clickSearch()"
								>                        
								</s-text>
							</v-col>
							<v-col cols="12" md="4" lg="3" class="pb-0 pt-1">
								<s-select-definition  
								clearable 
								v-model="filter.TypeFrozenCategory"                    
								:def="1370"
								label="Estado"
								@change="selectTypeFrozenCategory()"                        
								>                        
								</s-select-definition>
							</v-col>
							<v-col cols="12" md="4" lg="3" class="pb-0 pt-1">
								<s-select-definition 
								clearable
								:def="1318"  
								v-model="filter.TypeSelectionFrozen"                        
								label="Fondo/Flota"
								@change="selectTypeSelectionFrozen()">                        
								</s-select-definition>
							</v-col>            
							<v-col cols="12" md="4" lg="3" class="pb-0 pt-1">
								<s-select-definition  
								clearable
								:def="1390"                         
								label="Grupo"
								v-model="filter.CustomerGroup"
								@change="selectCustomerGroup()">                      
								</s-select-definition>
							</v-col>
							<v-col cols="12" md="4" lg="3" class="pb-0 pt-1">
								<s-select-definition
								clearable                                  
								:def="1173"                                 
								label="Cultivo"
								v-model="filter.TypeCultive"
								@change="selectTypeCultive()">                        
								</s-select-definition>
							</v-col>
							<v-col cols="12" md="4" lg="3" class="pb-0 pt-1">
								<s-select-variety
								clearable                 
								:cultiveID="filter.TypeCultive"
								full
								label="Variedad"
								:value="filter.VrtID"
								v-model="filter.VrtID"                  
								@input="selectVariedad()">                        
								</s-select-variety>
							</v-col>
							<v-col cols="12" md="4" lg="3" class="pb-0 pt-1">
								<s-select-definition 
								clearable
								:def="1172"
								v-model="filter.TypeCrop"                        
								label="Tipo de Cultivo"
								@change="selectTypeCrop()">
								</s-select-definition>
							</v-col>
							<v-col cols="12" md="4" lg="3" class="mt-2 pb-0">
								<v-btn
								small
								elevation="0"                            
								dark
								color="success"
								style="margin-right: 10px;"
								@click="ModalChangeGroup = true"
								>
								Cambiar Grupo
								</v-btn>
								<v-btn  small
									elevation="0"                            
									dark
									color="info"
									style="margin-right: 10px;"
									@click="MovePalletsGroup()">                  
									Mover Pallets
									</v-btn>
							</v-col>
							</v-col>
						</v-row>
					
					</v-container>

					<v-row justify="center"  md="12" lg="12" >                  
            			<v-col cols="12" md="12" lg="12" class="mt-3 pb-4">
							<v-data-table
								:headers="headers"
								:items="dataPrihuela"
								show-select
								v-model="itemSelected"
								item-key="McpID"
								
							>
								<!--:key="LlpID"-->
								<template v-slot:item.movimiento="{ item }">
									<v-btn
										x-small
										elevation="0"
										fab
										dark
										color="info"
										@click="viewMovimients(item)"
									>
										<v-icon>
											fas fa-eye
										</v-icon>
									</v-btn>
								</template>

								<template v-slot:item.enviarproceso="{ item }">
									<v-btn
										x-small
										elevation="0"
										fab
										dark
										color="success"
										@click="viewSendProccess(item)"
									>
										<i class="fas fa-share-square"></i>
									</v-btn>
								</template>
								
								<template v-slot:item.dias="{ item }">
									<v-btn 
										x-small 
										:color="item.MapDayNew < 4 ? 'info': 'error'"  
										style="z-index: 2;border-radius: 20px;">
											Días:  {{item.McpDayNew}} = {{item.McpMinuteNew}}
										</v-btn>
								</template>

								<template v-slot:item.estado="{ item }">
									<v-btn 
										:color="item.LlpBalancePartition == 1 ? 'warning' : item.LlpBalancePartition == 2 ? 'error' : item.LlpBalancePartition == 3 ? 'success' : ''" 
										v-if="item.LlpBalancePartition !== null" 
										style="border-radius: 20px;">
											{{item.LlpBalancePartition == 1 ? 'Partición' : item.LlpBalancePartition == 2 ? 'Union' : item.LlpBalancePartition == 3 ? 'Verde Proceso.' : ''}}
										</v-btn>
								</template>

								<template v-slot:item.LlpID="{ item }">
									{{ 'PA - ' + item.LlpID }}
								</template>

								<template v-slot:item.LlpWeight="{ item }">
									{{ item.LlpWeight + ' KG' }}
								</template>
								<template v-slot:item.changehour="{ item }">
									<v-btn
										x-small
										elevation="0"
										fab
										dark
										color="success"
										@click="openModalChangeHour(item)"
									>
										<i class="fas fa-clock"></i>
									</v-btn>
								</template>
								<template v-slot:item.changestate="{ item }">
									<v-btn 
										x-small
										elevation="0"
										fab
										dark
										color="primary"
										@click="openModalChangeStateTunel(item)" >
										<i class="fas fa-exchange-alt"></i>
									</v-btn>
								</template>
							</v-data-table>										
						</v-col>
					</v-row>
				</v-card>
			</div>			
		</v-row>
	
		<v-dialog persistent v-model="openModalMovimients" v-if="openModalMovimients" width="1200">
				<frz-pallet-movimients :itemMovimients="itemMovimients" @close="openModalMovimients=false"></frz-pallet-movimients>
		</v-dialog>
		<v-dialog     
          v-model="modalChangeHour"
        >
        <v-card>
            <v-container>
               	<v-row>
					<s-toolbar
					dark
					color="#BDBDBD"
					label="Cambiar Fecha de Ingreso"
					close
					@close="modalChangeHour = false"
					save
					@save="changeHour()"
					>
					</s-toolbar>
              	</v-row>
              	<v-row>
                	<v-col cols="4">
						<div>
                      		<s-date											
								v-model="ChangeDateTime"
							></s-date>
                    	</div>
						</v-col>
						<v-col cols="12" md="4" lg="3" class="mt-4 pb-0">
							<div class="relojito">
								<datetime valueZone="America/Lima"  type="time" v-model="ChangeHourTime">
								</datetime>
							</div>
						<!--<s-time 
							v-model="ChangeHourTime" 
							landscape                     
						></s-time>-->

						<!-- <s-text
						v-model="ChangeHourTime"
						type="time"
						landscape
						></s-text> -->

						<div class="relojito">
							<datetime title="Hora  :  Minutos" valueZone="America/Lima"  type="time" v-model="ChangeHourTime">
							</datetime>
						</div>



					</v-col>
              	</v-row>
            </v-container>
        </v-card>
        </v-dialog>
		<v-dialog
				v-model="viewModalSendProccess"
				persistent
				width="600"
			>
				<v-card>
					<v-container>
						<v-row>
							<s-toolbar
								dark
								color="#BDBDBD"
								label="Enviar a Proceso"
								close
								@close="viewModalSendProccess = false"
								save
								@save="sendProcess()"
							>

							</s-toolbar>
						</v-row>
						<v-row>
							<v-col cols="12">
								<sselect-client-sap
									v-model="palletProcess.CustomerID"
									label="Cliente"
								></sselect-client-sap>
							</v-col>
							<v-col cols="6">
								<s-select-definition
									v-model="palletProcess.TypeProcess"
									label="Tipo de Proceso"
									:def="1340"
								>
								</s-select-definition>
							</v-col>
							<v-col cols="6">
								<s-text
									v-model="palletProcess.PprWeightGross"
									decimal
									label="Peso Bruto"
									@input="inputWeightGross($event)"
								></s-text>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6">
								<s-text
									v-model="palletProcess.PprWeightJaba"
									decimal
									label="Peso Jaba"
									readonly
								></s-text>
							</v-col>
							<v-col cols="6">
								<s-text
									v-model="PprWeightNet"
									decimal
									label="Peso Neto"
								></s-text>
							</v-col>
							<v-col cols="6">
								<s-select
									label="Medio Ingreso"
									full
									item-value="SplID"
									item-text="SplName"
									:items="samplingLocation"
									v-model="palletProcess.SplID"
								></s-select>
							</v-col>
							<!-- agregamos el tipo de parihuela samir-->
										
							<v-col cols="6">
							<s-select-definition
								label="Tipo parihuela"
								v-model="TypePallet"
								:def="1363"
								return-object
								@input="selectTypePallet($event)">
							</s-select-definition>
							</v-col>
							 
						</v-row>
					</v-container>
				</v-card>
		</v-dialog>

		<v-dialog
			persistent
			v-model="ModalChangeGroup"
			v-if="ModalChangeGroup && itemSelected.length > 0"      
			>
			<v-card>
				<v-row>
					<v-col>
					<s-toolbar  
						color="primary"                  
						dark
						close
						@close="ModalChangeGroup = false"
						label="Fechas"
					>
					</s-toolbar>
					</v-col>
				</v-row>
				<v-container>
				
				<v-row>
					<v-col cols="3">
						<s-select-definition 
						:def="1390" 
						label="Grupos" 
						
						v-model="TypeCustomerGroup"
						
							>
						</s-select-definition>                          
					</v-col>   
				</v-row>
				<v-row>
					<v-col cols="3">
					<v-btn
						small
						elevation="0"                            
						dark
						color="success"
						@click="ChangeCustomerGroup"
					>
						Cambiar
					</v-btn>                         
					</v-col>
				</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog 
			persistent 
			v-model="modalChangeTunnel" 
			v-if="modalChangeTunnel"
			transition="dialog-bottom-transition"			
			width="1200">
				<frz-pallet-change
					:IDMaturation="itemChange.McpID"
					:LlpID="itemChange.LlpID"
					:dataTunnelDescription="dataCalifornian.ClfName"
					:IDTunnelCurrent="dataCalifornian.ClfID"
					:TypeCultive="itemChange.TypeCultive"
          			:VrtID="itemChange.VrtID"
					close
					@close="modalChangeTunnel = false"
					@closeDetail="refresh">
				</frz-pallet-change>
		</v-dialog>
		
		<v-dialog 
			persistent 
			v-model="modalChangeGroup" 
			v-if="modalChangeGroup"
			
			width="1200">
				<frz-change-group
				:IDMaturation="itemChange.McpID"
				:itemSelected="itemSelected"								
				:dataTunnelDescription="dataCalifornian.ClfName"
				:IDTunnelCurrent="dataCalifornian.ClfID"
				:TypeCultive="itemChange.TypeCultive"
				:VrtID="itemChange.VrtID"
				close
				:itemsSelected="itemsSelected"
				@close="modalChangeGroup = false"
				@closeDetail="refresh">
			</frz-change-group>
		</v-dialog>
	</v-card>
	
</template>

<script>

	import { Datetime } from 'vue-datetime';

	import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';
	import FrzCustomCardParihuelaCalifornian from "./FrzCustomCardParihuelaCalifornian.vue";
	import FrzPalletMovimients from "@/views/FrozenProduction/PalletInTunnel/FrzPalletMovimients.vue";
	import _sPalletProcessService from "@/services/FrozenProduction/PalletProcessService.js"
	import SselectClientSap from '@/components/Sale/SselectClientSap.vue';
	import _sSamplingLocationService from '@/services/FrozenProduction/SamplingLocationService.js';
	import _sParameter from "@/services/General/ParameterService.js";

	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";

	import FrzPalletChange from "../PalletInTunnel/FrzPalletChange.vue";  
	import FrzChangeGroup from "../PalletInTunnel/FrzPalletChangeGroup.vue";  
	export default {
		props: {
			dataCalifornian: null,
			ClfID:null,
			// dataPrihuela: null
		},

		components: {
			FrzCustomCardParihuelaCalifornian, 
			FrzPalletMovimients, 
			SselectClientSap, 
			SSelectVariety, 
			FrzPalletChange,
			Datetime,
			FrzChangeGroup
		},

		data() {
			return {
				dataPrihuela: [],
				filter: {
					FtmID:  "",
					LlpCorrelative : "",
					TypeFrozenCategory : "",
					TypeSelectionFrozen: "",
					TypeCrop: "",
					TypeCultive: null,
					VrtID: "",
					CustomerGroup: "",
				},  
				itemSelected: [],
				TypeCustomerGroup: 0,
				ModalChangeGroup: false,
				ChangeHourTime: "",
				ItemChangeHour: {},
				modalChangeHour: false,
				PprWeightNet: 0,
				TypePalletItems:[],
				TypePallet: 0,
				TypePalletHelper: 0,
				headers:[
					{ text: "ID", value: "LlpID", width:"70" },
					{ text: "Pallet Ticket", value: "LlpCorrelative", width:"70", align: "center"},
					{ text: "Movimientos", value: "movimiento", width: "100", align:"center"},
					{ text: "Grupo", value: "TypeCustomerGroupName", width: "100", align: "center" },
					
					{ text: "Días Estado", value: "DaysStateActual", width:"70", align: "center"},
					{ text: "Días en Recepción", value: "DaysInReception", width:"70", align: "center"},
					{ text: "Días Conservando", value: "DaysConserving", width:"70", align: "center"},
					{ text: "Días Madurando", value: "DaysMaturation", width:"70", align: "center"},
					{ text: "Días Maduro", value: "DaysMaturo", width:"70", align: "center"},
					{ text: "Días Totales", value: "DaysReception" , width:"70", align: "center"},
					
					{ text: "Certificaciones", value: "Certifications" },
					{ text: "N° Jabas", value: "LlpQuantityJaba" },
					
					{ text: "Peso Neto", value: "LlpWeightOriginalNet" },
					
					{ text: "Variedad", value: "VrtName" },
					{ text: "Entrada", value: "McpDateBegin" },
					{ text: "Cambiar Fecha y Hora", value: "changehour", align: "center"},
					{ text: "Cambiar Ambiente", value: "changestate", align: "center" },
				],
				itemsPositionAll: [],
				alert: true,
				itemMovimients: [],
				openModalMovimients:false,

				palletProcess: {},
				viewModalSendProccess: false,
				samplingLocation: [],
				modalChangeTunnel: false,
				itemChange: [],
				IDTunnelCurrent: 0,
				ChangeDateTime: '',
				itemSelected: [],
				modalChangeGroup: false
			}
		},

		methods: {
			MovePalletsGroup(){
				if(this.itemSelected.length > 0){
					this.modalChangeGroup = true;
					this.itemsSelected = this.itemSelected;					
				}			
			},
			openModalChangeStateTunel(item)
			{
				this.modalChangeTunnel = true;
				this.itemChange = item;				
			},
			ChangeCustomerGroup(){

				this.$fun.alert("¿Desea Cambiar el Grupo?", "question")
				.then((r) => {

					if (r.value) {
					let list = [];
					this.itemSelected.forEach(element => {
						list.push(element.McpID);
					});

					

					let items = {};
					items.List = list;
					items.flag = 3;
					items.TypeCustomerGroup = this.TypeCustomerGroup;

					_sMaturationTunnelPalletService.ChangeCustomerGroup(items, this.$fun.getUserID())
					.then((r) => {
						if (r.status == 200) {
						this.$fun.alert("Los Datos de Guardaron correctamente", "success");
						this.ModalChangeGroup = false;
						this.clickSearch();
						}
					})
					}

				});

				},
			changeHour(){

				if(this.ChangeHourTime == ""){
					this.$alert("No ha Ingresado la hora", "warning");
					return;
				}
					
				
				this.$fun.alert("¿Seguro de cambiar la hora?", "question")
				.then(r => {
					if(r.value){
						_sMaturationTunnelPalletService.changeHour(
						{ 
							ID: this.ItemChangeHour.LlpID ,
							Hour: this.ChangeHourTime,
							Type: "CALIFONIAN"
						}
						, this.$fun.getUserID())
						.then((r) => {
						
						if(r.data){
								this.$fun.alert("Pallet enviado a proceso correctamente", "success");
								this.modalChangeHour = false;
								this.listInitial()
							}
						});
					}
				});

			},
			openModalChangeHour(item){

				
				this.ChangeHourTime = this.$fun.formatTimeShortView(this.$moment().format("hh:mm"));

				this.modalChangeHour = true;
				

				this.ItemChangeHour = item;
			},
			selectTypePallet(item){
				if (item != undefined) {
					
					this.TypePalletHelper = item.DedHelper;
					this.inputWeightGross(0); 					  
				}  
			},
			inputWeightGross(weight)
			{
				this.PprWeightNet = this.palletProcess.PprWeightGross - (this.palletProcess.PprWeightJaba*this.palletProcess.PprQuantityJaba)
				- this.TypePalletHelper;
				if(this.palletProcess.PprWeightNet < 0){
					this.palletProcess.PprWeightNet = 0;
				}
			},

			getDataInitial(data)
			{
				data.forEach(element => {
					if(element.DedValue == 1){
						//this.palletProcess.PprWeightJaba = element.DedHelper;
					}
				})
				
			},

			generatedPositions(){
               	for (var i = 0; i < this.dataCalifornian.ClfCapacity; i++) {
                    var objrrr = {id: (i+1), name: "Posición 0" + (i+1), children: []}
                    this.itemsPositionAll.push(objrrr)
                }


					this.itemsPositionAll.forEach(element => {
                        
                        element.children = []
                        var obkkk = this.dataPrihuela.find((x) => x.McpPosition == element.id)

                        if(obkkk !== undefined){
                            element.children.push(obkkk)
                            this.dataPrihuela = this.dataPrihuela.filter(
                                x => x.LlpID != obkkk.LlpID,
                            );
                        }
                        
                    });
            },


			viewMovimients(item){

				_sMaturationTunnelPalletService
				.listMovimients({LlpID: item.LlpID}, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){

						resp.data.forEach(element => {
							element.MtpDateBegin = this.$moment(element.MtpDateBegin).format(
								this.$const.FormatDateTimeDB
							);

							if(element.MtpDateEnd !== null){
								element.MtpDateEnd = this.$moment(element.MtpDateEnd).format(
									this.$const.FormatDateTimeDB
								);
							}

							
						});

						this.itemMovimients = resp.data
						

						this.openModalMovimients  =true
					}
				})


				
			},

			viewSendProccess(item)
			{
				
				this.viewModalSendProccess = true;
				this.palletProcess.LlpID = item.LlpID;
				this.palletProcess.SecStatus = 1;
				this.palletProcess.UsrCreateID = this.$fun.getUserID();
				this.palletProcess.TypeSelectionFrozen = item.TypeSelectionFrozen;
				this.palletProcess.TypeFrozenCategory = item.TypeFrozenCategory;
				this.palletProcess.PprQuantityJaba = item.LlpQuantityJaba;
				this.palletProcess.TypeCrop = item.TypeCrop;
				this.palletProcess.TypeCultive = item.TypeCultive;
				this.palletProcess.VrtID = item.VrtID;
				this.palletProcess.FtsID = item.FtsID;
				this.palletProcess.PprOrigin = 4;
				this.palletProcess.PprOriginID = item.McpID;

				//samir ingrese peso bruto
				this.TypePallet = this.TypePalletItems.find( x => x.DedValue == item.TypePallet);			 
				this.palletProcess.PprWeightGross = item.LlpWeightOriginalGross;
				//this.inputWeightGross(0);   


				_sParameter.search({ PrmName: "pesoJabaCongelado"}, this.$fun.getUserID()).then((r) => {
				
				this.palletProcess.PprWeightJaba  = r.data.PrmValue;
				});
				_sSamplingLocationService
				.list(this.palletProcess, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
					this.samplingLocation = resp.data;
					
					}
				})
			},

			sendProcess()
			{
				if(this.palletProcess.CustomerID == undefined || this.palletProcess.CustomerID == null){
					this.$fun.alert("Ingrese cliente", "warning");
					return;
				}
				if(this.palletProcess.PprWeightNet == 0){
					this.$fun.alert("Ingrese peso", "warning");
					return;
				}

				this.palletProcess.PprWeightNet =  this.PprWeightNet;
				this.palletProcess.TypePallet = this.TypePallet.DedValue;

				this.$fun.alert("¿Seguro de envíar a proceso?", "question")
				.then(r => {
					if(r.value){
						_sPalletProcessService
						.save(this.palletProcess, this.$fun.getUserID())
						.then((resp) => {
							if(resp.status == 200){
								this.$fun.alert("Pallet enviado a proceso correctamente", "success");
								this.viewModalSendProccess = false;
								this.closeDetail();
								//this.generatedPositions();
							}
						});
						
					}
				})
			},

			selectTypeSelectionFrozen()
			{
				this.filter.TypeSelectionFrozen = this.filter.TypeSelectionFrozen;
				this.clickSearch();
			},

			selectTypeFrozenCategory()
			{
				this.filter.TypeFrozenCategory = this.filter.TypeFrozenCategory;
				this.clickSearch();
			},

			selectTypeCrop()
			{
				this.filter.TypeCrop = this.filter.TypeCrop;
				this.clickSearch();
			},

			selectTypeCultive()
			{
				this.filter.TypeCultive = this.filter.TypeCultive;  
				this.clickSearch();
			},

			selectVariedad()
			{      
				this.filter.VrtID = this.filter.VrtID;      
				this.clickSearch();
			},

			selectCustomerGroup()
			{
				this.filter.CustomerGroup = this.filter.CustomerGroup;          
				this.clickSearch();
			},

			clickSearch()
			{    
				this.filter.ClfID =  this.ClfID;  
				this.filter.LlpCorrelative = this.filter.LlpCorrelative; 
				_sMaturationTunnelPalletService
				.palletinsidecalifornian(this.filter, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){

						resp.data.forEach(element => {
							element.McpDateBegin = this.$moment(element.McpDateBegin).format(
								this.$const.FormatDateTimeDB
							);
							element.McpSecondsNew = 
								this.$fun.isDifferenceMinutes(
									element.McpDateNewBegin,
									element.McpDateNewEnd,
									"seconds"
								) * -1;

							element.McpMinuteNew = this.$fun.secondsToString(element.McpSecondsNew)
						});


						/* MtpPosition */

						this.dataPrihuela = resp.data;
						this.dialog = true;
					}
				})
			},
			
			closeDetail() {
				this.$emit('closeDetail');
			
			},

			refresh(){
				this.modalChangeTunnel = false;
				this.modalChangeGroup = false;
				this.clickSearch();
			},
			listInitial(){
				this.filter.ClfID =  this.ClfID;  
			_sMaturationTunnelPalletService
				.palletinsidecalifornian(this.filter, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){

						resp.data.forEach(element => {
							element.McpDateBegin = this.$moment(element.McpDateBegin).format(
								this.$const.FormatDateTimeDB
							);
							element.McpSecondsNew = 
								this.$fun.isDifferenceMinutes(
									element.McpDateNewBegin,
									element.McpDateNewEnd,
									"seconds"
								) * -1;

							element.McpMinuteNew = this.$fun.secondsToString(element.McpSecondsNew)
						});


						/* MtpPosition */

						this.dataPrihuela = resp.data;						
					}
				})
			}
		},



		created () {
			
			//this.generatedPositions();

			
		},

		mounted()
		{
			this.listInitial()
		}
	}
</script>

<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
  }
  </style>









































/* 			Initialize()
			{
				let filter = {
					FtmID: this.dataCalifornian.FtmID,
				}

				_sMaturationTunnelPalletService
				.list(filter, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){
						this.pallets = resp.data;
						
					}
				})
			}, */
	<v-col
							cols="12"
							lg="3"
							md="6"
							v-for="item in pallets"
							:key="item.MtpID"
						>
							<v-card dense class="pb-2">
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>
											<!-- <v-chip
												color="#BBDEFB"
											>
												{{item.PrfYuliano}}
											</v-chip>
											
											<v-chip 
												small
												v-if="item.LlpIsSaldo == 1"
												color="red"
												text-color="white"
											>
												{{"Saldo"}}
											</v-chip> -->
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="my-2"></v-divider>
								<v-card-text class="justify-end">
									<!-- <v-row>
										<v-col cols="6">
											<h4>N° Parihuela: {{item.LlpID}}</h4>
											<h4>{{item.TypeSelectionFrozenName}}</h4>
											<h4>{{item.TypeFrozenCategoryName}}</h4>
											<h4>{{item.TypeCropName}}</h4>
											<h4>{{item.TypeCultiveName}}</h4>
											<h4>{{item.VrtName}}</h4>
											<h4>{{item.LlpQuantityJaba + " Jabas"}}</h4>
											<h4>Fecha/Hora Entrada: {{item.MtpDateBegin}}</h4>
										</v-col>
										<v-col cols="6">
											<v-img
												lazy-src="../../../assets/pallets44.png"
												max-height="150"
												max-width="150"
												src="../../../assets/pallets44.png"
												class="rounded-lg"
												>
											</v-img>
										</v-col>
									</v-row> -->
								</v-card-text>
							</v-card>
						</v-col>











												<v-col
								cols="6"
								lg="3"
								md="4"
								v-for="col in pallets"
							>
								<v-card style="display: grid; background: #eaeaea;">
									<v-card-title>
										<span class="text-h6 font-weight-light"
											>{{ }}
											<!-- <v-chip :color="'info'">
												{{ col.TypePalletHelper }}
											</v-chip> -->
										</span>
									</v-card-title>
									<v-img
										lazy-src="../../../assets/pallets44.png"
										max-height="200"
										max-width="200"
										src="../../../assets/pallets44.png"
										><v-btn
											style="border-radius: 100%; height: 44%; width: 30%; margin: auto; display: flex; justify-content: space-evenly; "
											color="warning"
											@click="openModal(col)"
										>
											<span class="white--text text-h4">{{
												
											}}</span>
										</v-btn>
									</v-img>
								</v-card>
								<v-row style="margin-top: 0.1em;">
									<v-col cols="7">
										<s-select-definition
											:def="1181"
											style="border-right: groove;"
										
										/>
									</v-col>
									<v-col cols="5">
										<v-btn
											outlined
											small
											elevation="3"
											color="success"
											style="width:100%"
											@click="clickSend(col)"
										>
											Enviar
										</v-btn>
									</v-col>
								</v-row>
							</v-col>