import { render, staticRenderFns } from "./PalletInCalifornianDetail.vue?vue&type=template&id=72b2a6e8&"
import script from "./PalletInCalifornianDetail.vue?vue&type=script&lang=js&"
export * from "./PalletInCalifornianDetail.vue?vue&type=script&lang=js&"
import style0 from "./PalletInCalifornianDetail.vue?vue&type=style&index=0&id=72b2a6e8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./PalletInCalifornianDetail.vue?vue&type=custom&index=0&blockType=v-col&cols=12&lg=3&md=6&v-for=item%20in%20pallets&%3Akey=item.MtpID"
if (typeof block0 === 'function') block0(component)
import block1 from "./PalletInCalifornianDetail.vue?vue&type=custom&index=1&blockType=v-col&cols=6&lg=3&md=4&v-for=col%20in%20pallets"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VChip,VCol,VContainer,VDataTable,VDialog,VIcon,VRow})
