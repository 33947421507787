
<template>
	<div>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;" >
							<v-col
								align="center"
								cols="8"
								lg="4"
								md="4"
								v-for="item in itemsCalifornian"
								:key="item.ClfID"
							>
								<!-- <v-btn
									x-small
									elevation="0"
									fab
									dark
									color="error"
									style="margin-left: 5px; margin-top: 0px"
								>
									<v-icon style="font-size: 16px !important"
										>fas fa-eye</v-icon
									>
								</v-btn> -->


								<v-card 
									class="mx-auto"
									dense
									:color="item.inCalifornianStateColor"
									
								><!-- item.inCalifornianStateColor -->
									<!-- <v-card-title class="justify-center">
										<v-card :color="item.inCalifornianStateColor" outlined width="200"><h4>{{ item.ClfName }}</h4></v-card>
										
									</v-card-title> -->
									
									<v-img
										lazy-src="https://img.interempresas.net/fotos/2852264.jpeg"
										
										src="https://img.interempresas.net/fotos/2852264.jpeg"
										>
										<v-row style="margin: auto;">
											<!-- <v-col cols="12" class="mt-0">
												<v-btn  v-if="item.inParihuelaUsage > 0" small color="error"  style="z-index:;transform: rotateZ(0deg);margin-top: -2%;margin-left: -30%;position: absolute;width: 133px;border-radius: 20px;">Max. Dias Parih.: {{item.ClfDay}}</v-btn>
											</v-col> -->
											<v-col cols="6" lg="6" class="mt-0">
												<h1 v-if="item.ClfTemperatureBegin > 0"><v-chip small color="info">Temp: {{item.ClfTemperatureBegin}}</v-chip></h1>
											</v-col>
											<v-col cols="6" lg="6" class="mt-0">
												<h1 v-if="item.ClfEthyleneHour > 0"><v-chip small color="info">H. Etile: {{item.ClfEthyleneHour}}</v-chip></h1>
											</v-col>
										</v-row>
										<v-btn
											style=" margin: auto; display: flex; justify-content: space-evenly; "
											:color="item.inColor"
											fab
											@click="openModal(item)"
											:class="item.McpTemperatureBegin > 0 ? 'mt-1' : 'mt-8'"
											width="100"
											height="100"
											class="mb-8"
										>
											<span class="white--text text-h4"><b>
												<h1 style="font-size: 20px; ">
													{{item.inParihuelaUsage + ' TM'}}
												</h1>
											</b></span>
										</v-btn>
										<frz-tons-in-day
											:TonsInDay="item.TonsInDay">
										</frz-tons-in-day>		
									</v-img>
									<v-card :color="item.inCalifornianStateColor" outlined width="200"><h3>{{ item.ClfName }}</h3></v-card>
									<!-- <v-card-actions class="justify-center">
										<v-btn
											fab
											:color="item.inColor"
											style="opacity: 0.7"
											@click="openModal(item)"
											elevation="0"
										>
											<b>
												<h1 style="font-size: 50px; ">
													{{item.inParihuelaUsage}}
												</h1>
											</b>
										</v-btn>
										
									</v-card-actions> -->
								</v-card>
								<!-- <v-row style="margin-top: 0.1em;">
									<v-col :cols="12">
										<s-select
											class="mt-1"
											:items="itemsStateCalifornian"
											item-value="MtsID"
											item-text="MtsDescription"
											:slotTmp="true"
											return-object
											v-model="item.inCalifornianState"
											@input="inputState($event, item)"
										>
											 <template v-slot:item="data">
												{{ data.item.MtsDescription }}
											</template> 
										</s-select>
									</v-col>
									<v-col cols="3">
										<v-btn
											outlined
											small
											fab
											elevation="3"
											color="error"
											v-if="item.inParihuelaUsage > 0"
											@click="openDialogExitTunnel(item)"
										>
											<v-icon style="font-size: 16px !important"
												>fas fa-eye</v-icon
											>
										</v-btn>
									</v-col> -->
									<!--<v-col cols="3" v-if="item.inParihuelaUsage > 0">
										 <s-select-definition 
											:def="1389" label="Grupo" 
											 
											>
										 </s-select-definition>
                                   
									</v-col>
								</v-row> -->
								<!-- <s-select-definition class="mt-2" :def="1359" v-model="item.inTunnelState"></s-select-definition> -->
								
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-model="dialog"
			v-if="dialog"
			transition="dialog-bottom-transition"
			persistent
			fullscreen
		>
			<pallet-in-californian-detail
				@closeDetail = "closeDetail"
				:dataCalifornian="dataCalifornian"
				:ClfID="ClfID"
			></pallet-in-californian-detail>
		</v-dialog>
		<!-- :dataPrihuela="dataPrihuela" -->
		<v-dialog
			v-model="dialogExitTunnel"
			width="500"
			persistent
		>
			<v-card>
				<v-container>
					<v-row>
						<s-toolbar
							color="#BDBDBD"
							label="Parametros"
							save
							@save="saveProcessGroup()"
							close
							@close="closeDialogExitTunnel()"
							dark
						>

						</s-toolbar>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpTemperatureBegin"
								label="Temperatura"
							>

							</s-text>
						</v-col>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpEthyleneHour"
								label="Horas etileno"
							>

							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';
	import sTunnelMaduration from "../../../services/FrozenProduction/TunnelMadurationService";
	import PalletInCalifornianDetail from './PalletInCalifornianDetail.vue';
	import _sCalifornianService from "@/services/FrozenProduction/MaturationCalifornianService.js"
	import FrzTonsInDay from '../FrzMaduration/FrzTonsInDay.vue';
	import signalr from "signalr";

	export default {
		components: { 
			PalletInCalifornianDetail, FrzTonsInDay
		},

		data() {
			return {
				processGroup: {},
				dialogExitTunnel: false,
				itemsCalifornian: [],
				itemsStateCalifornian: [],
				dataCalifornian: {},
				dialog:false,
				dataPrihuela: []
			}
		},

		methods: {

			saveProcessGroup()
			{
				
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value){
						_sMaturationTunnelPalletService
						.beginprocessgroup(this.processGroup ,this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Guardado Correctamente", "success");
								this.closeDialogExitTunnel();
							}
						})
					}
				})
			},

			openDialogExitTunnel(item)
			{
				this.processGroup.xFlag = 3;
				this.processGroup.TcaID = item.ClfID;
				this.processGroup.UsrUpdateID = this.$fun.getUserID();
				this.dialogExitTunnel = true;
			},

			closeDialogExitTunnel()
			{
				this.dialogExitTunnel = false;
				this.initialize()
			},

			initialize(){

				_sMaturationTunnelPalletService.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateCalifornian = r.data
					}
				})


				sTunnelMaduration.listCalifornian({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.ClfCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.ClfCapacity)- parseInt(element.inParihuelaUsage)
							
							
							//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}

							element.TonsInDay.forEach(tonelas => {
								const fecha = new Date(tonelas.FechaIngreso);
								const date = fecha.getDate();
								const month = fecha.getMonth() + 1;								
								const format = date + '/'+ month;
								tonelas.FechaIngreso = format;								
							});
						});

						this.itemsCalifornian = r.data;
						

					}
					
				});
			},

			inputState(item, itemCalifornian){
				
				if(item == itemCalifornian.MtsID ){
					return
				}

				itemCalifornian.ChangeState = 10
				itemCalifornian.MtsID = item.MtsID
				itemCalifornian.UsrUpdateID = this.$fun.getUserID();

				
	
				this.$fun.alert("¿Seguro de Cambiar de Estado de " + itemCalifornian.ClfName +" a " +item.MtsDescription +"?", "question").then(val => {
					if(val.value){
						_sCalifornianService.save(itemCalifornian,this.$fun.getUserID() ).then(resp => {
							if(resp.status == 200){ 
								this.$fun.alert("Cambio realizado correctamente", "success")
								this.initialize()
							}
						})
					}

				})
			},

			openModal(item)
			{
				this.dataCalifornian = item;
				this.ClfID = item.ClfID;
				this.dialog = true;
                
				
				// let filter = {
				// 	ClfID: item.ClfID,
				// }

				// _sMaturationTunnelPalletService
				// .palletinsidecalifornian(filter, this.$fun.getUserID()).then(resp => {
				// 	if(resp.status == 200){

				// 		resp.data.forEach(element => {
				// 			element.McpDateBegin = this.$moment(element.McpDateBegin).format(
				// 				this.$const.FormatDateTimeDB
				// 			);
				// 			element.McpSecondsNew = 
				// 				this.$fun.isDifferenceMinutes(
				// 					element.McpDateNewBegin,
				// 					element.McpDateNewEnd,
				// 					"seconds"
				// 				) * -1;

				// 			element.McpMinuteNew = this.$fun.secondsToString(element.McpSecondsNew)
				// 		});


				// 		/* MtpPosition */

				// 		this.dataPrihuela = resp.data;
				// 		this.dialog = true;
				// 	}
				// })

				
			},

			closeDetail()
			{
				this.dialog = false;
				this.initialize()
			}

			/* Initialize() {
				_sMaturationTunnelPalletService.palletsinside(this.$fun.getUserID())
				.then(resp => {	
					if(resp.status == 200){
						this.destinations = resp.data;
						this.destinations = this.destinations.filter( e => {
							return e.DedDescription != "Californiano"
						});
						this.destinations = this.destinations.filter( e => {
							return e.DedDescription != "Selección"
						});
						this.destinations = this.destinations.filter( e => {
							return e.DedDescription != "Proceso"
						});
					}
				})
			}, */

			

			
		},

		created () {
			this.initialize();
		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
	}
</script>